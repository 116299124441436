export default {
  api: {
    url: 'https://prodapi.datedish.com/datedish/api/v1/',
    mode: 'cors'
  },
  config: {

  }

}
